<template>
    <mercur-card class="full-height-layout fill mx-4 mb-3">
        <grid-header :quickSearch.sync="filters.search">
            Accounts {{$route.params.organisationId ? organisationName : ''}}
            <template slot="actions">
                <mercur-button class="btn btn-yellow btn-raised text-uppercase" @click="addNewAccount" v-if="isAllowedTo('SupplierCentral/createAccount')">Add virtual supplier account</mercur-button>
            </template>
        </grid-header>
        <data-table
            class="fill full-height-layout border"
            ref="grid"
            :options="options"
            :url="url"
            :quickSearch="filters.search"
            v-if="isAllowedTo('SupplierCentral/listAccounts')"
        />

        <p class="permission-message" v-else>Not allowed to see this view</p>

        <mercur-dialog :is-open.sync="accountAddDialogActive" width="800px">
            <div slot="default">
                <h2 class="font-weight-normal mt-0">Account</h2>
                <form @submit.prevent="saveAccount">
                    <div class="row">
                        <div class="col-6">
                            <mercur-input v-model.trim="$v.accountAddDialogParams.firstName.$model" :disabled="accountAddDialogLoading" required :class="{'form-invalid': $v.accountAddDialogParams.firstName.$error}">
                                First name*
                                <template #note>
                                    <span class="form-error" v-if="$v.accountAddDialogParams.firstName.$error">First name is required</span>
                                </template>
                            </mercur-input>
                        </div>
                        <div class="col-6">
                            <mercur-input  v-model.trim="$v.accountAddDialogParams.lastName.$model" :disabled="accountAddDialogLoading" required :class="{'form-invalid': $v.accountAddDialogParams.lastName.$error}">
                                Last name*
                                <template #note>
                                    <span class="form-error" v-if="$v.accountAddDialogParams.lastName.$error">Last name is required</span>
                                </template>
                            </mercur-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-5">
                            <mercur-input v-model.trim="$v.accountAddDialogParams.phone.$model" type="tel" :disabled="accountAddDialogLoading">
                                Phone
                            </mercur-input>
                        </div>
                        <div class="col-7">
                            <mercur-input v-model.trim="$v.accountAddDialogParams.email.$model" :disabled="accountAddDialogLoading" required :class="{'form-invalid': $v.accountAddDialogParams.email.$error}">
                                Email*
                                <template #note>
                                    <span class="form-error" v-if="$v.accountAddDialogParams.lastName.$error">Valid email is required</span>
                                </template>
                            </mercur-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pretty-select
                                class="mb-3"
                                v-if="locales"
                                placeholder="Language"
                                :options="locales"
                                :reduce="locale => locale.tag"
                                :get-option-label="locale => `${locale.name || ''} ${locale.location ? ' - ' + locale.location : ''} (${locale.tag})`"
                                v-model="$v.accountAddDialogParams.language.$model"></pretty-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pretty-select
                                class="mb-3"
                                v-if="availableRoles"
                                v-model="$v.accountAddDialogParams.roles.roleIds.$model"
                                :disabled="accountAddDialogLoading"
                                :options="availableRoles"
                                :reduce="role => role.roleId"
                                label="roleName"
                                placeholder="Role"
                                :multiple="true"
                            ></pretty-select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <pretty-select
                                class="mb-3"
                                v-if="availableOrganisations"
                                placeholder="Organisation"
                                :options="availableOrganisations"
                                :reduce="organisation => organisation.organisationId"
                                :get-option-label="organisation => `${organisation.organisationName} (${organisation.organisationType})`"
                                v-model="$v.accountAddDialogParams.organisationId.$model"></pretty-select>
                        </div>
                    </div>
                </form>
            </div>
            <div slot="footer">
                <mercur-button class="btn text-uppercase" @click.native="accountAddDialogActive = false">Close</mercur-button>
                <mercur-button class="btn text-uppercase" :disabled="accountAddDialogLoading || $v.accountAddDialogParams.$invalid" @click.native="saveAccount">Save</mercur-button>
            </div>
        </mercur-dialog>
    </mercur-card>
</template>

<script>
import CONFIG from '@root/config'
import DataTable from '../../components/DataTable'
import GridHeader from '../../components/GridHeader'
import { required, email } from 'vuelidate/lib/validators'
import PrettySelect from '../../components/utils/PrettySelect'
import * as locales from 'locale-codes'

export default {
    name: 'AccessControlOverview',
    components: { PrettySelect, GridHeader, DataTable },
    props: {
        organisationId: {
            default: null,
        },
    },
    data () {
        return {
            locales: locales.all.filter(locale => locale.name !== 'Pseudo Language'),
            url: null,
            availableRoles: null,
            availableOrganisations: null,
            organisationName: null,
            isSaving: false,
            options: {
                columns: {
                    'Name': {
                        field: 'lastName',
                        valueGetter: ({ data }) => `${data.firstName} ${data.lastName}`,
                        sortable: true,
                    },
                    'Organisation': {
                        field: 'organisationName',
                        sortable: true,
                        link: (value, data) => {
                            return {
                                name: 'AccessControlOverview',
                                params: {
                                    organisationId: data.organisationId,
                                },
                            }
                        },
                    },
                    'Account type': {
                        field: 'accountType',
                    },
                    'Email': {
                        field: 'email',
                    },
                    'Username': {
                        field: 'username',
                    },
                    'Phone': {
                        field: 'phone',
                    },
                    'Status': {
                        maxWidth: 105,
                        field: 'dateRevoked',
                        statusChip: true,
                        valueGetter: ({ data }) => data.dateRevoked ? 'Revoked' : 'Active',
                    },
                    'organisationId': {
                        hide: true,
                        field: 'organisationId',
                    },
                },
                actions: [
                    {
                        text: 'Edit',
                        tooltipText: 'Edit',
                        disabled: ({ data }) => !this.isAllowedTo('updateAccount'),
                        onClick: ({ data }) => {
                            this.openEditAccountDialog(data)
                        },
                    },
                    {
                        text: 'Revoke',
                        tooltipText: 'Revoke account',
                        disabled: ({ data }) => !!data.dateRevoked && !this.isAllowedTo('SupplierCentral/revokeAccount'),
                        onClick: ({ data }) => {
                            this.setAccessForAccount('REVOKE', data)
                        },
                    },
                    {
                        text: 'Reactivate',
                        tooltipText: 'Reactivate account',
                        disabled: ({ data }) => !data.dateRevoked && !this.isAllowedTo('SupplierCentral/reactivateAccount'),
                        onClick: ({ data }) => {
                            this.setAccessForAccount('REACTIVATE', data)
                        },
                    },
                ],
                quickSearchColumns: ['lastName', 'firstName', 'email', 'username', 'phone', 'organisation'],
                onDataReceived: (data) => {
                    this.organisationName = this.$route.params.organisationId && data && data[0] ? data[0].organisationName : null
                },
            },
            filters: {
                search: '',
            },
            accountAddDialogActive: false,
            accountAddDialogLoading: false,
            accountAddDialogParams: {
                firstName: null,
                lastName: null,
                phone: null,
                email: null,
                roles: {
                    roleIds: [],
                    roleNames: null,
                },
                language: 'en-GB',
                organisationId: null,
            },
        }
    },

    validations: {
        accountAddDialogParams: {
            firstName: { required },
            lastName: { required },
            phone: {},
            email: { email },
            roles: {
                roleIds: {},
                roleNames: {},
            },
            language: { required },
            organisationId: { required },
        },
    },

    methods: {
        openEditAccountDialog (data) {
            this.$set(this.$v.accountAddDialogParams, '$model', data)
            this.$nextTick(() => (this.$v.accountAddDialogParams.$reset()))
            this.accountAddDialogActive = true
        },
        addNewAccount () {
            this.accountAddDialogActive = true
            this.accountAddDialogParams.accountId = null
            this.$v.accountAddDialogParams.$model.organisationId = this.organisationId
            this.$nextTick(() => (this.$v.accountAddDialogParams.$reset()))
        },
        saveAccount () {
            if (this.accountAddDialogParams.accountId === null && !this.isAllowedTo('SupplierCentral/createAccount')) {
                return
            }
            let url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.VIRTUAL_SUPPLIER.CREATE.replace('{organisationId}', this.accountAddDialogParams.organisationId)

            if (this.accountAddDialogParams.accountId) {
                if (!this.isAllowedTo('SupplierCentral/updateAccount')) {
                    return
                }
                url = CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.VIRTUAL_SUPPLIER.UPDATE.replace('{accountId}', this.accountAddDialogParams.accountId)
            }

            this.addJob(url)
            this.isSaving = true
            this.accountAddDialogLoading = true
            this.$api.post(url, this.accountAddDialogParams).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Account successfully saved`,
                })
                this.accountAddDialogActive = false
                this.$set(this, 'accountAddDialogParams', {
                    firstName: null,
                    lastName: null,
                    phone: null,
                    email: null,
                    roles: {
                        roleIds: [],
                        roleNames: null,
                    },
                    organisationId: null,
                })
                this.$refs.grid.refreshGrid()
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `Saving account failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.isSaving = false
                this.accountAddDialogLoading = false
            })
        },
        setAccessForAccount (type, data) {
            if (type === 'REVOKE') {
                if (!this.isAllowedTo('SupplierCentral/revokeAccount')) {
                    return
                }
            } else {
                if (!this.isAllowedTo('SupplierCentral/reactivateAccount')) {
                    return
                }
            }

            let url = (type === 'REVOKE' ? CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.VIRTUAL_SUPPLIER.REVOKE : CONFIG.API.ROUTES.ACCESS_CONTROL.ACCOUNT.VIRTUAL_SUPPLIER.REACTIVATE)

            url = url.replace('{accountId}', data.accountId)

            this.addJob(url)
            this.isSaving = true
            this.$api.post(url).then(() => {
                this.$root.$emit('notification:global', {
                    message: `Account successfully ${type === 'REVOKE' ? 'revoked' : 'reactivated'}`,
                })
                this.$refs.grid.refreshGrid()
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: `${type === 'REVOKE' ? 'Revoking' : 'Reactivating'} account failed. Please try again.`,
                    type: 'error',
                    errors: data,
                })
            }).finally(() => {
                this.finishJob(url)
                this.isSaving = false
            })
        },
    },

    watch: {
        'organisationId': {
            handler () {
                this.organisationName = ''
                if (this.organisationId) {
                    this.url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_FOR_ORGANISATION.replace('{organisationId}', this.organisationId)

                    return
                }

                this.url = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW
            },
            immediate: true,
        },
    },
    created () {
        const rolesUrl = CONFIG.API.ROUTES.ACCESS_CONTROL.ROLES.OVERVIEW
        this.addJob(rolesUrl)
        this.$api.post(rolesUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.availableRoles = data.data.items
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting roles failed`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(rolesUrl)
        })

        const organisationsUrl = CONFIG.API.ROUTES.ACCESS_CONTROL.OVERVIEW_ORGANISATIONS
        this.addJob(organisationsUrl)
        this.$api.post(organisationsUrl, CONFIG.API.REQUEST_DEFAULT).then(({ data }) => {
            this.availableOrganisations = data.data.items
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting organisations failed`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(organisationsUrl)
        })
    },
}
</script>
